@font-face {
  font-family: 'FranceTV Brown';
  src: url('/assets/fonts/FranceTVBrown-Regular.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FranceTV Brown';
  src: url('/assets/fonts/FranceTVBrown-Bold.otf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'FranceTV Brown';
  src: url('/assets/fonts/FranceTVBrown-Thin.otf');
  font-weight: 300;
  font-style: normal;
}
