/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

$red-color : #EE7656;
$blue-color: #EB1637;
$green-color: #77BA63;
$orange-color: #F59F40;

/* For use in src/lib/core/theming/_palette.scss */
$md-primary: (
  50 : #e6eff1,
  100 : #c0d6dc,
  200 : #96bbc5,
  300 : #6c9fad,
  400 : #4d8b9c,
  500 : #2d768a,
  600 : #286e82,
  700 : #226377,
  800 : #1c596d,
  900 : #11465a,
  A100 : #91deff,
  A200 : #5ecfff,
  A400 : #2bc0ff,
  A700 : #12b8ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$md-accent: (
  50 : #e5f4f3,
  100 : #bee4e1,
  200 : #93d3cd,
  300 : #67c1b8,
  400 : #47b3a9,
  500 : #26a69a,
  600 : #229e92,
  700 : #1c9588,
  800 : #178b7e,
  900 : #0d7b6c,
  A100 : #adfff3,
  A200 : #7affec,
  A400 : #47ffe4,
  A700 : #2dffe0,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$app-primary:  mat.define-palette($md-primary);
$app-accent: mat.define-palette($md-accent);
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat.define-light-theme($app-primary, $app-accent, $app-warn);

$text-color: #505050;
$background-color: #FAFAFA;

/* ------------------- Colors most used in components ------------------- */
$main-accent-color: #D80A19;
$main-accent-green:  #86BC2D;
$main-white-color: #ffffff;
$main-text-color: #222323;
$main-accent-green-color: #86BC2D;

$button-very-light-grey-color: #f0f0f0;
$button-light-grey-color: #e4e5e5;

$alternative-cell-color: #EEEEEE;
$cell-clear-button-color: #808080;

$black-color-transparent: rgba(31, 32, 32, 0.3);

$clickable-bubble-background: #F9F9F9;

$shady-grey: #C5C5C5;
