@use '@angular/material' as mat;
@import 'styles/_lib';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'styles/_colors';

@include mat.core();

// Include all theme styles for the components.
@include mat.all-component-themes($app-theme);

body {
  padding: 0;
  margin: 0;
  background: $background-color;
}

body, html, * {
  font-family: 'FranceTV Brown', sans-serif;
}

body, .mat-card {
  color: $text-color;
}

.bold { font-weight: bold}

.capitalize { text-transform: capitalize}

button[mat-button], button[mat-raised-button] { text-transform: inherit }
button[mat-button]:first-letter, button[mat-raised-button]:first-letter { text-transform: capitalize !important; }

.button-rounded {
  border-radius: 24.5px !important;
}

.big-button-rounded {
  height: 49px !important;
  width: 219px !important;
  border-radius: 24.5px !important;
}

/* Dimension */
.full-width { width: 100% !important; }
.half-width { width: 49%; }
.third-width { width: 33%; }
.two-third-width { width: 66%; }

.full-heigth { height: 100% !important; }

/* Material */
div[mat-dialog-actions] { margin-bottom: 0px !important }

.no-margin { margin:0 !important }
.no-margin-left { margin-left:0 !important}
.no-margin-right { margin-right:0 !important}
.no-margin-top { margin-top:0 !important}
.no-margin-bottom { margin-bottom:0 !important}

.inline { display: inline }
.contents { display: contents }

.alert { color: #f70e1d }

// Bootstrap grid
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;

  }
}
@media (min-width: 1200px) {
  .container {
    width: 1370px;
  }
}
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.row {
  margin-left: -15px;
  margin-right: -15px;
}
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
}
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}
.col-xs-12 {
  width: 100%;
}
.col-xs-11 {
  width: 91.66666667%;
}
.col-xs-10 {
  width: 83.33333333%;
}
.col-xs-9 {
  width: 75%;
}
.col-xs-8 {
  width: 66.66666667%;
}
.col-xs-7 {
  width: 58.33333333%;
}
.col-xs-6 {
  width: 50%;
}
.col-xs-5 {
  width: 41.66666667%;
}
.col-xs-4 {
  width: 33.33333333%;
}
.col-xs-3 {
  width: 25%;
}
.col-xs-2 {
  width: 16.66666667%;
}
.col-xs-1 {
  width: 8.33333333%;
}
.col-xs-pull-12 {
  right: 100%;
}
.col-xs-pull-11 {
  right: 91.66666667%;
}
.col-xs-pull-10 {
  right: 83.33333333%;
}
.col-xs-pull-9 {
  right: 75%;
}
.col-xs-pull-8 {
  right: 66.66666667%;
}
.col-xs-pull-7 {
  right: 58.33333333%;
}
.col-xs-pull-6 {
  right: 50%;
}
.col-xs-pull-5 {
  right: 41.66666667%;
}
.col-xs-pull-4 {
  right: 33.33333333%;
}
.col-xs-pull-3 {
  right: 25%;
}
.col-xs-pull-2 {
  right: 16.66666667%;
}
.col-xs-pull-1 {
  right: 8.33333333%;
}
.col-xs-pull-0 {
  right: auto;
}
.col-xs-push-12 {
  left: 100%;
}
.col-xs-push-11 {
  left: 91.66666667%;
}
.col-xs-push-10 {
  left: 83.33333333%;
}
.col-xs-push-9 {
  left: 75%;
}
.col-xs-push-8 {
  left: 66.66666667%;
}
.col-xs-push-7 {
  left: 58.33333333%;
}
.col-xs-push-6 {
  left: 50%;
}
.col-xs-push-5 {
  left: 41.66666667%;
}
.col-xs-push-4 {
  left: 33.33333333%;
}
.col-xs-push-3 {
  left: 25%;
}
.col-xs-push-2 {
  left: 16.66666667%;
}
.col-xs-push-1 {
  left: 8.33333333%;
}
.col-xs-push-0 {
  left: auto;
}
.col-xs-offset-12 {
  margin-left: 100%;
}
.col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.col-xs-offset-0 {
  margin-left: 0%;
}
@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
}
.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
  content: " ";
  display: table;
}
.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
  clear: both;
}
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.hidden {
  display: none !important;
}
.affix {
  position: fixed;
}

.card_sticky {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 237px !important;
  z-index: 2 !important;
}

.title {
  font-weight: 700;
  margin-bottom: 20px;
  font-size: 18px;
}

.btn-danger {
  background: #d80b19 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.warning-color {
  color: #f59f40;
}

.xxl {
  height: 39px;
  color: #222323;
  font-size: 32px;
  font-weight: bold;
  line-height: 39px;
}

.mat-grid-tile {
  align-items: baseline !important;
  justify-content: right !important;
  display: initial !important;
}

.mat-divider-dashed {
  border-top-style: dashed !important;
}

.stroked-button {
  border: 2px solid #222323 !important;
  border-radius: 24.5px !important;
  height: 49px;
  width: 260px;
}

.pointer { cursor: pointer; }
.grap { cursor: grab; }

.main-title {
  height: 44px;
  color: #222323;
  font-family: "FranceTV Brown", sans-serif;
  font-size: 36px;
  font-weight: 300;
  line-height: 44px;
}

.chip {
  border-radius: 18.5px;
  background-color: #F0F1F1;
  padding: 10px;
  display: inline-block;
  color: #6F7373;
  font-size: 1.2em;
  margin-right: 10px;
}
.chip-success {
  background-color: $green-color !important;
  color: #FFF !important;
}
.chip-error {
  background-color: $main-accent-color !important;
  color: white !important;
}
.chip-warning {
  background-color: $orange-color !important;
  color: #FFF !important;
}

.alert-warning {
  background-color: #f59f40 !important;
  color: #FFF !important;
}
.alert-error {
  background-color: #F44336 !important;
  color: #FFF !important;
}
.alert-success {
  background-color: #86BC25 !important;
  color: #FFF !important;
}

.vertical-separator {
  display: inline;
  border: 1px solid #DDE1E6;
  border-radius: 1px;
}

.soft-link {
  height: 17px;
  color: #222323;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.error-box {
  box-sizing: border-box;
  border: 1px solid #E41536;
  border-radius: 6px;
  background-color: #FDE8EB;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  color: #E41536;
}

.chip-blue {
  background-color: #009CB4 !important;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  border-radius: 8px !important;
}

.mat-chip.mat-standard-chip {
  border-radius: 8px !important;
}

.mat-form-field.suffix-icon-alignment {
  .mat-form-field-flex{
    align-items: flex-end;
  }
  .mat-form-field-suffix {
    display: flex;
    align-items: flex-end;
  }
}
/* Material ui grey button without overlay */
.mat-button.open-popup-button .mat-button-focus-overlay {
  display: none;
}

/* Material ui select without arrow and central test alighnment */
.mat-form-field.custom-select-without-down-arrow {
  .mat-select-arrow {
    border: none;
    width: 0;
  }
  .mat-select-value{
    text-align: center;
  }
  .mat-select-value-text {
    margin-left: 6px;
  }
}

.text-line-through {
  text-decoration: line-through;
}


.mat-grid-tile-content {
  position: initial !important;
  display: block !important;
}

.mat-form-field.flex-alignment-meteo-rules {
  .mat-form-field-flex{
    position: relative;
    bottom: -5px;
  }
}

.mat-form-field.flex-select-alignment-meteo-rules {
  .mat-form-field-flex{
    position: relative;
    bottom: -3px;
  }
}

/* -- Custom Radio Buton Styles -- */
.custom-no-circle-radio-button-group {
  mat-radio-button {
    border-radius: 4px;
    .mat-radio-container{
      display: none;
    }
  }
  .mat-radio-label-content{
    padding: 8px 10px;
    font-size: 16px;
    color: black;
  }
}



.horizontal-light-grey-separator {
  height: 1px;
  width: 100%;
  background-color: $button-very-light-grey-color;
  margin-top: 20px;
  margin-bottom: 20px;
}

.horizontal-light-grey-separator-small-margin {
  height: 1px;
  width: 100%;
  background-color: $button-very-light-grey-color;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* ---- Inventory Offer ---- */
.action-button-with-icon {
  mat-icon {
    display: inline-block;
    height: 18px;
    width: 18px;
    font-size: 18px;
    margin-left: 4px;
  }
}

.selection-type-toggle-button-active {
  background-color: $button-light-grey-color;
}
.selection-type-toggle-button-inactive{
  &:hover {
    background-color: $button-light-grey-color;
  }
}

/* --------------- List Button Styles ------------------ */
.list-button{
  cursor: pointer;
  text-transform: none;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: none;
  margin-left: 5px;
  background-color: $button-very-light-grey-color;
  &:active, &:focus {
    outline: none;
  }
  &:hover {
    background-color: $button-light-grey-color;
  }
}
.list-icon {
  font-size: 26px;
}
.list-button-label {
  margin-right: 10px;
}
.list-button-grey-label {
  color: rgba(0,0,0, 0.6);
}

/*--------------- Search Input Validate Clear Styles ------------------*/
.check-clear-buttons-container {
  display: flex;
}
.search-input-button-icon {
  cursor: pointer;
  color: rgba(31, 32, 32, 0.57);
  border-radius: 4px;
  padding: 2px;
  &:hover {
    background-color: $button-light-grey-color;
  }
}

/* ------------------- Selection Summary for targeting and ranges ------------------- */
.selection-summary-container{
  display: flex;
}
.selection-summary {
  width: 30%;
}
.selection-summary-middle {
  margin-left: 5%;
  margin-right: 5%;
}

.ml-1 {
  margin-left: 10px;
}

.text-center {
  text-align: center;
}


