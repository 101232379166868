@use '@angular/material' as mat;
@include mat.core();

// Vars
$red-color : #D80A19;
$blue-color: #EB1637;
$green-color: #77BA63;
$orange-color: #F59F40;

$dark-blue-color: #124a59;
$purple-color: #77164e;
$ligth-gray-color: #efefef;
$gray-color: #6F7373;
$black: #000;

/******************************/
/********** PALETTES **********/
/******************************/
$primary-palette: (
        50 : #e4e5e5,
        100 : #bdbdbd,
        200 : #919191,
        300 : #646565,
        400 : #434444,
        500 : #222323,
        600 : #1e1f1f,
        700 : #191a1a,
        800 : #141515,
        900 : #0c0c0c,
        A100 : #e76c6c,
        A200 : #e04040,
        A400 : #ec0000,
        A700 : #d30000,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #ffffff,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #ffffff,
                A400 : #ffffff,
                A700 : #ffffff,
        )
);

$warn-palette: (
    50 : #fae2e3,
    100 : #f3b6ba,
    200 : #ec858c,
    300 : #e4545e,
    400 : #de303c,
    500 : #d80b19,
    600 : #d40a16,
    700 : #ce0812,
    800 : #c8060e,
    900 : #bf0308,
    A100 : #ffe7e7,
    A200 : #ffb4b5,
    A400 : #ff8183,
    A700 : #ff6869,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$accent-palette: (
        50 : #f0f7e5,
        100 : #dbebbe,
        200 : #c3de92,
        300 : #aad066,
        400 : #98c646,
        500 : #86bc25,
        600 : #7eb621,
        700 : #73ad1b,
        800 : #69a516,
        900 : #56970d,
        A100 : #e2ffc6,
        A200 : #c8ff93,
        A400 : #aeff60,
        A700 : #a1ff47,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #000000,
                600 : #000000,
                700 : #000000,
                800 : #000000,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

// ALTERNATE
$primary-alternate-palette: (
  50 : #e3e9eb,
  100 : #b8c9cd,
  200 : #89a5ac,
  300 : #59808b,
  400 : #366572,
  500 : #124a59,
  600 : #104351,
  700 : #0d3a48,
  800 : #0a323e,
  900 : #05222e,
  A100 : #68ccff,
  A200 : #35bbff,
  A400 : #02aaff,
  A700 : #009ae7,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #ffffff,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #ffffff,
  )
);

$my-app-primary: mat.define-palette($primary-palette);
$my-app-accent:  mat.define-palette($accent-palette);
$my-app-warn:    mat.define-palette($warn-palette);

$my-app-theme: mat.define-light-theme($my-app-primary, $my-app-accent, $my-app-warn);

@include mat.all-component-themes($my-app-theme);


.alternate-theme {
  $alternate-primary: mat.define-palette($primary-alternate-palette);

  $alternate-theme: mat.define-light-theme($alternate-primary, $my-app-accent, $my-app-warn);

  @include mat.all-component-themes($alternate-theme);
}
/******************************/
/******** END PALETTES ********/
/******************************/


/* General */
body { background: #F0F1F1 }

/* Dimension */
.full-width { width: 100%; }
.half-width { width: 49%; }
.third-width { width: 33%; }
.two-third-width { width: 66%; }

h1.main-title {
  text-transform: none;
  margin: 0px;
  margin-top: 15px;
}

.right {
  float: right
}

.left {
  float: left;
}

/* Material */
div[mat-dialog-title] {
  h2 { margin-top:0px }
}
div[mat-dialog-actions] { margin-bottom: 0px !important }

button {
  text-transform: uppercase;
}

a[mat-button] {
  text-decoration: none;
  font-weight: bold
}

// .mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
//   background-color: transparent ;
// }
// .mat-checkbox-checkmark-path {
//   stroke: black !important;
// }

.blue { color: $blue-color }
.red { color: $red-color; }
.green { color: $green-color }
.blue { color: #28768b};
.gray { color: $gray-color }
.orange { color: #ee7656}

.warn { color: #d80b19 }

.mg-10 {
  margin: 10px;
}

.align-text-center {
  text-align: center;
}

.circle {
  vertical-align: top;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  background-color: #333;
  text-align: center;
  mark {
    color: #fff;
    background-color: transparent !important;
    font-size: 13px;
  }
  mark.big {
    font-size: 12px;
  }
}
.circle.big {
  width: 30px;
  height: 30px;
}

.padding-10 {
  padding: 10px;
}

h1 {
  height: 44px;
  color: #222323;
  font-size: 36px;
  font-weight: 300;
  line-height: 44px;
}

.h2 {
   height: 37px;
   color: #222323;
   font-size: 30px;
   line-height: 37px;
}

.h3 {
  height: 27px;
  width: 342px;
  color: #222323;
  font-family: "FranceTV Brown";
  font-size: 22px;
  line-height: 27px;
}

p {
  color: #222323;
  font-size: 16px;
  line-height: 19px;
}

.mat-dialog-container {
  padding: 50px !important;
}